import React from 'react';
import { 
  ArrowLeft,
  MessageSquare,
  Clock,
  Globe,
  HeadphonesIcon,
  ArrowRight,
  Zap,
  Users
} from 'lucide-react';
import { useNavigate } from 'react-router-dom';
import { useLanguage } from '../context/LanguageContext';

export default function Support() {
  const navigate = useNavigate();
  const { t } = useLanguage();

  const features = [
    {
      icon: Clock,
      title: t('features.support.title'),
      description: t('features.support.desc')
    },
    {
      icon: Globe,
      title: t('support.features.multilingual'),
      description: t('support.features.multilingualDesc')
    },
    {
      icon: MessageSquare,
      title: t('support.features.chat'),
      description: t('support.features.chatDesc')
    },
    {
      icon: HeadphonesIcon,
      title: t('support.features.experts'),
      description: t('support.features.expertsDesc')
    }
  ];

  const channels = [
    {
      title: t('support.channels.chat'),
      description: t('support.channels.chatDesc'),
      available: true
    },
    {
      title: t('support.channels.email'),
      description: t('support.channels.emailDesc'),
      available: true
    },
    {
      title: t('support.channels.phone'),
      description: t('support.channels.phoneDesc'),
      available: true
    }
  ];

  return (
    <section className="py-20 bg-gradient-to-b from-gray-50 to-white">
      <div className="container mx-auto px-4">
        <button 
          onClick={() => navigate('/')}
          className="mb-8 flex items-center gap-2 text-indigo-600 hover:text-indigo-700 transition-colors"
        >
          <ArrowLeft className="w-5 h-5" />
          {t('button.backHome')}
        </button>

        <div className="max-w-3xl mx-auto text-center mb-16">
          <h1 className="text-4xl font-bold text-gray-900 mb-4">
            {t('support.title')}
          </h1>
          <p className="text-xl text-gray-600">
            {t('support.subtitle')}
          </p>
        </div>

        {/* Fonctionnalités */}
        <div className="grid md:grid-cols-2 lg:grid-cols-4 gap-8 mb-16">
          {features.map((feature, index) => (
            <div key={index} className="bg-white rounded-xl shadow-lg p-6">
              <div className="w-12 h-12 bg-indigo-100 rounded-lg flex items-center justify-center mb-4">
                <feature.icon className="w-6 h-6 text-indigo-600" />
              </div>
              <h3 className="text-lg font-semibold text-gray-900 mb-2">
                {feature.title}
              </h3>
              <p className="text-gray-600">
                {feature.description}
              </p>
            </div>
          ))}
        </div>

        {/* Canaux de Support */}
        <div className="grid md:grid-cols-3 gap-8 mb-16">
          {channels.map((channel, index) => (
            <div key={index} className="bg-white rounded-xl shadow-lg p-6">
              <div className="flex items-center justify-between mb-4">
                <h3 className="text-lg font-semibold text-gray-900">
                  {channel.title}
                </h3>
                <span className={`px-3 py-1 rounded-full text-sm ${
                  channel.available 
                    ? 'bg-green-100 text-green-600' 
                    : 'bg-gray-100 text-gray-600'
                }`}>
                  {channel.available ? t('support.status.available') : t('support.status.unavailable')}
                </span>
              </div>
              <p className="text-gray-600">
                {channel.description}
              </p>
            </div>
          ))}
        </div>

        {/* CTA */}
        <div className="bg-indigo-600 rounded-2xl p-8 lg:p-12 text-white">
          <div className="grid md:grid-cols-2 gap-8 items-center">
            <div>
              <h3 className="text-3xl font-bold mb-4">
                {t('support.cta.title')}
              </h3>
              <p className="text-indigo-100 mb-6">
                {t('support.cta.subtitle')}
              </p>
              <button className="bg-white text-indigo-600 px-8 py-3 rounded-lg font-semibold hover:bg-indigo-50 transition flex items-center gap-2">
                {t('support.cta.button')}
                <ArrowRight className="w-5 h-5" />
              </button>
            </div>
            <div className="grid grid-cols-2 gap-4 text-center">
              <div className="bg-white/10 rounded-lg p-4 backdrop-blur-sm">
                <Zap className="w-8 h-8 text-white mx-auto mb-2" />
                <div className="text-3xl font-bold mb-1">2min</div>
                <div className="text-indigo-100">{t('support.stats.response')}</div>
              </div>
              <div className="bg-white/10 rounded-lg p-4 backdrop-blur-sm">
                <Users className="w-8 h-8 text-white mx-auto mb-2" />
                <div className="text-3xl font-bold mb-1">98%</div>
                <div className="text-indigo-100">{t('support.stats.satisfaction')}</div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}