import React, { useState } from 'react';
import { Menu, X, Brain } from 'lucide-react';
import { useLanguage } from '../context/LanguageContext';
import LanguageSelector from './LanguageSelector';
import GetStarted from './GetStarted';
import { useNavigate } from 'react-router-dom';

export default function Header() {
  const [isOpen, setIsOpen] = useState(false);
  const { t } = useLanguage();
  const navigate = useNavigate();

  const menuItems = [
    { label: t('nav.solutions'), path: '/solutions', items: [
      { label: t('nav.analysis'), path: '/analyse-ia' },
      { label: t('nav.recommendations'), path: '/recommandations' },
      { label: t('nav.dashboard'), path: '/dashboard' },
    ]},
    { label: t('nav.tools'), path: '/tools', items: [
      { label: t('nav.prompts'), path: '/prompt-library' },
      { label: t('nav.content'), path: '/content-writing' },
    ]},
    { label: t('nav.services'), path: '/services', items: [
      { label: t('nav.training'), path: '/training' },
      { label: t('nav.collaboration'), path: '/collaboration' },
      { label: t('nav.support'), path: '/support' },
    ]},
    { label: t('nav.pricing'), path: '/pricing' },
  ];

  const [activeDropdown, setActiveDropdown] = useState<number | null>(null);

  return (
    <header className="fixed w-full bg-white/80 backdrop-blur-md z-50 border-b border-gray-100">
      <nav className="container mx-auto px-4 sm:px-6 lg:px-8">
        <div className="flex items-center justify-between h-16 sm:h-20">
          <div className="flex items-center gap-2 cursor-pointer" onClick={() => navigate('/')}>
            <Brain className="h-8 w-8 text-indigo-600" />
            <div className="flex flex-col">
              <span className="text-lg sm:text-xl font-bold text-indigo-600">AImagination</span>
              <span className="text-xs sm:text-sm text-gray-500">{t('header.tagline')}</span>
            </div>
          </div>
          
          <div className="hidden md:flex items-center gap-6 lg:gap-8">
            {menuItems.map((item, index) => (
              <div 
                key={index}
                className="relative group"
                onMouseEnter={() => setActiveDropdown(index)}
                onMouseLeave={() => setActiveDropdown(null)}
              >
                <button className="text-gray-600 hover:text-indigo-600 transition-colors py-2">
                  {item.label}
                </button>
                {item.items && (activeDropdown === index || document.activeElement === document.querySelector(`#dropdown-${index}`)) && (
                  <div 
                    id={`dropdown-${index}`}
                    className="absolute top-full left-0 w-48 py-2 mt-1 bg-white rounded-lg shadow-lg"
                    onMouseEnter={() => setActiveDropdown(index)}
                    onMouseLeave={() => setActiveDropdown(null)}
                  >
                    {item.items.map((subItem, subIndex) => (
                      <button
                        key={subIndex}
                        className="w-full px-4 py-2 text-left text-gray-600 hover:bg-indigo-50 hover:text-indigo-600 transition-colors"
                        onClick={() => {
                          navigate(subItem.path);
                          setActiveDropdown(null);
                        }}
                      >
                        {subItem.label}
                      </button>
                    ))}
                  </div>
                )}
              </div>
            ))}
            <LanguageSelector />
            <GetStarted />
          </div>

          <button 
            className="md:hidden p-2"
            onClick={() => setIsOpen(!isOpen)}
            aria-label="Menu"
          >
            {isOpen ? <X className="h-6 w-6" /> : <Menu className="h-6 w-6" />}
          </button>
        </div>

        {isOpen && (
          <div className="md:hidden py-4 border-t border-gray-100">
            <div className="flex flex-col gap-4">
              {menuItems.map((item, index) => (
                <div key={index} className="space-y-2">
                  <div className="font-medium text-gray-900 px-2">
                    {item.label}
                  </div>
                  {item.items && (
                    <div className="pl-4 space-y-2">
                      {item.items.map((subItem, subIndex) => (
                        <button
                          key={subIndex}
                          className="w-full text-left text-gray-600 hover:text-indigo-600 px-2 py-1"
                          onClick={() => {
                            navigate(subItem.path);
                            setIsOpen(false);
                          }}
                        >
                          {subItem.label}
                        </button>
                      ))}
                    </div>
                  )}
                </div>
              ))}
              <div className="px-2 py-1">
                <LanguageSelector />
              </div>
              <div className="px-2 py-1">
                <GetStarted />
              </div>
            </div>
          </div>
        )}
      </nav>
    </header>
  );
}