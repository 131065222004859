import React, { useState } from 'react';
import { 
  Building2, 
  Users, 
  BriefcaseIcon, 
  Mail, 
  Phone, 
  Lock, 
  CheckCircle2,
  Clock 
} from 'lucide-react';
import { useLanguage } from '../context/LanguageContext';

interface FormData {
  companyName: string;
  industry: string;
  size: string;
  email: string;
  phone: string;
}

const CONTACT_EMAIL = 'divers@distr-action.com';

export default function AuditForm() {
  const { t } = useLanguage();
  const [step, setStep] = useState(1);
  const [formData, setFormData] = useState<FormData>({
    companyName: '',
    industry: '',
    size: '',
    email: '',
    phone: ''
  });
  const [isSubmitted, setIsSubmitted] = useState(false);

  const industries = [
    'audit.industry.tech',
    'audit.industry.finance',
    'audit.industry.health',
    'audit.industry.retail',
    'audit.industry.manufacturing',
    'audit.industry.services',
    'audit.industry.other'
  ];

  const companySizes = [
    'audit.size.1-10',
    'audit.size.11-50',
    'audit.size.51-200',
    'audit.size.201-500',
    'audit.size.500+'
  ];

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    // Here you would typically send the form data to your backend
    // For now, we'll just simulate a successful submission
    setIsSubmitted(true);
  };

  const renderStep1 = () => (
    <div className="space-y-6">
      <div>
        <label className="block text-sm font-medium text-gray-700 mb-2">
          {t('audit.form.company.name')}
        </label>
        <div className="relative">
          <Building2 className="absolute left-3 top-1/2 -translate-y-1/2 text-gray-400 h-5 w-5" />
          <input
            type="text"
            value={formData.companyName}
            onChange={(e) => setFormData({...formData, companyName: e.target.value})}
            className="pl-10 w-full rounded-lg border border-gray-200 py-3 px-4 focus:border-indigo-500 focus:ring-2 focus:ring-indigo-200 outline-none transition-all"
            placeholder={t('audit.form.company.placeholder')}
            required
          />
        </div>
      </div>

      <div>
        <label className="block text-sm font-medium text-gray-700 mb-2">
          {t('audit.form.industry')}
        </label>
        <div className="relative">
          <BriefcaseIcon className="absolute left-3 top-1/2 -translate-y-1/2 text-gray-400 h-5 w-5" />
          <select
            value={formData.industry}
            onChange={(e) => setFormData({...formData, industry: e.target.value})}
            className="pl-10 w-full rounded-lg border border-gray-200 py-3 px-4 focus:border-indigo-500 focus:ring-2 focus:ring-indigo-200 outline-none transition-all appearance-none"
            required
          >
            <option value="">{t('audit.form.industry.placeholder')}</option>
            {industries.map((industry) => (
              <option key={industry} value={industry}>{t(industry)}</option>
            ))}
          </select>
        </div>
      </div>

      <div>
        <label className="block text-sm font-medium text-gray-700 mb-2">
          {t('audit.form.size')}
        </label>
        <div className="relative">
          <Users className="absolute left-3 top-1/2 -translate-y-1/2 text-gray-400 h-5 w-5" />
          <select
            value={formData.size}
            onChange={(e) => setFormData({...formData, size: e.target.value})}
            className="pl-10 w-full rounded-lg border border-gray-200 py-3 px-4 focus:border-indigo-500 focus:ring-2 focus:ring-indigo-200 outline-none transition-all appearance-none"
            required
          >
            <option value="">{t('audit.form.size.placeholder')}</option>
            {companySizes.map((size) => (
              <option key={size} value={size}>{t(size)}</option>
            ))}
          </select>
        </div>
      </div>
    </div>
  );

  const renderStep2 = () => (
    <div className="space-y-6">
      <div>
        <label className="block text-sm font-medium text-gray-700 mb-2">
          {t('audit.form.email')}
        </label>
        <div className="relative">
          <Mail className="absolute left-3 top-1/2 -translate-y-1/2 text-gray-400 h-5 w-5" />
          <input
            type="email"
            value={formData.email}
            onChange={(e) => setFormData({...formData, email: e.target.value})}
            className="pl-10 w-full rounded-lg border border-gray-200 py-3 px-4 focus:border-indigo-500 focus:ring-2 focus:ring-indigo-200 outline-none transition-all"
            placeholder={t('audit.form.email.placeholder')}
            required
          />
        </div>
      </div>

      <div>
        <label className="block text-sm font-medium text-gray-700 mb-2">
          {t('audit.form.phone')}
        </label>
        <div className="relative">
          <Phone className="absolute left-3 top-1/2 -translate-y-1/2 text-gray-400 h-5 w-5" />
          <input
            type="tel"
            value={formData.phone}
            onChange={(e) => setFormData({...formData, phone: e.target.value})}
            className="pl-10 w-full rounded-lg border border-gray-200 py-3 px-4 focus:border-indigo-500 focus:ring-2 focus:ring-indigo-200 outline-none transition-all"
            placeholder={t('audit.form.phone.placeholder')}
            required
          />
        </div>
      </div>

      <div className="flex items-start gap-3 text-sm text-gray-500">
        <Lock className="flex-shrink-0 h-5 w-5 text-gray-400" />
        <p>{t('audit.form.privacy')}</p>
      </div>
    </div>
  );

  const renderSuccess = () => (
    <div className="text-center py-8">
      <div className="w-16 h-16 bg-green-100 rounded-full flex items-center justify-center mx-auto mb-6">
        <CheckCircle2 className="h-8 w-8 text-green-500" />
      </div>
      <h3 className="text-2xl font-bold text-gray-900 mb-4">
        {t('audit.form.success.title')}
      </h3>
      <p className="text-gray-600 mb-6">
        {t('audit.form.success.message')}
      </p>
      <div className="flex justify-center gap-4">
        <div className="flex items-center gap-2">
          <Mail className="h-5 w-5 text-indigo-600" />
          <span className="text-gray-600">{CONTACT_EMAIL}</span>
        </div>
        <div className="flex items-center gap-2">
          <Clock className="h-5 w-5 text-indigo-600" />
          <span className="text-gray-600">{t('audit.form.success.response')}</span>
        </div>
      </div>
    </div>
  );

  return (
    <div className="max-w-2xl mx-auto bg-white rounded-2xl shadow-xl p-8">
      {!isSubmitted ? (
        <form onSubmit={handleSubmit}>
          <div className="text-center mb-8">
            <h2 className="text-3xl font-bold text-gray-900 mb-4">
              {t('audit.form.title')}
            </h2>
            <p className="text-gray-600">
              {t('audit.form.subtitle')}
            </p>
          </div>

          <div className="mb-8">
            <div className="flex justify-between items-center">
              {[1, 2].map((i) => (
                <div
                  key={i}
                  className={`flex-1 h-2 rounded-full mx-2 ${
                    i <= step ? 'bg-indigo-600' : 'bg-gray-200'
                  }`}
                />
              ))}
            </div>
            <div className="flex justify-between mt-2">
              <span className="text-sm font-medium text-gray-600">{t('audit.form.step1')}</span>
              <span className="text-sm font-medium text-gray-600">{t('audit.form.step2')}</span>
            </div>
          </div>

          {step === 1 ? renderStep1() : renderStep2()}

          <div className="flex justify-between mt-8">
            {step === 2 && (
              <button
                type="button"
                onClick={() => setStep(1)}
                className="px-6 py-3 text-indigo-600 hover:text-indigo-700 font-medium transition-colors"
              >
                {t('audit.form.back')}
              </button>
            )}
            {step === 1 ? (
              <button
                type="button"
                onClick={() => setStep(2)}
                className="ml-auto bg-indigo-600 text-white px-6 py-3 rounded-lg font-medium hover:bg-indigo-700 transition-colors"
              >
                {t('audit.form.next')}
              </button>
            ) : (
              <button
                type="submit"
                className="ml-auto bg-indigo-600 text-white px-8 py-3 rounded-lg font-medium hover:bg-indigo-700 transition-colors"
              >
                {t('audit.form.submit')}
              </button>
            )}
          </div>
        </form>
      ) : (
        renderSuccess()
      )}
    </div>
  );
}