import React from 'react';
import { 
  Sparkles, 
  Code2, 
  History, 
  Share2, 
  Zap,
  BookOpen,
  RefreshCw,
  MessageSquareMore
} from 'lucide-react';
import { useLanguage } from '../context/LanguageContext';

export default function PromptMaster() {
  const { t } = useLanguage();

  const categories = [
    {
      icon: Code2,
      nameKey: 'category.development',
      prompts: "450+",
      color: "bg-blue-100 text-blue-600"
    },
    {
      icon: MessageSquareMore,
      nameKey: 'category.marketing',
      prompts: "380+",
      color: "bg-green-100 text-green-600"
    },
    {
      icon: BookOpen,
      nameKey: 'category.education',
      prompts: "290+",
      color: "bg-purple-100 text-purple-600"
    },
    {
      icon: Share2,
      nameKey: 'category.sales',
      prompts: "320+",
      color: "bg-orange-100 text-orange-600"
    }
  ];

  return (
    <section id="prompts" className="py-20 bg-white">
      <div className="container mx-auto px-6">
        <div className="text-center mb-16">
          <span className="text-indigo-600 font-semibold text-sm uppercase tracking-wide">
            {t('promptmaster.tag')}
          </span>
          <h2 className="text-4xl font-bold text-gray-900 mt-2 mb-4">
            {t('promptmaster.title')}
          </h2>
          <p className="text-xl text-gray-600 max-w-2xl mx-auto">
            {t('promptmaster.subtitle')}
          </p>
        </div>

        <div className="grid lg:grid-cols-2 gap-12 items-center mb-20">
          <div className="relative">
            <div className="absolute inset-0 bg-gradient-to-tr from-indigo-500/30 to-purple-500/30 rounded-3xl blur-3xl"></div>
            <img 
              src="https://images.unsplash.com/photo-1519389950473-47ba0277781c?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=2070&q=80"
              alt="PromptMaster Interface"
              className="relative rounded-2xl shadow-2xl"
            />
          </div>

          <div className="space-y-8">
            <div className="space-y-6">
              <h3 className="text-2xl font-bold text-gray-900">
                {t('promptmaster.management.title')}
              </h3>
              <div className="grid gap-6">
                <div className="flex gap-4 items-start">
                  <div className="flex-shrink-0 w-12 h-12 rounded-lg bg-indigo-100 flex items-center justify-center">
                    <Sparkles className="w-6 h-6 text-indigo-600" />
                  </div>
                  <div>
                    <h4 className="text-lg font-semibold text-gray-900">{t('promptmaster.ai.title')}</h4>
                    <p className="text-gray-600">{t('promptmaster.ai.description')}</p>
                  </div>
                </div>
                <div className="flex gap-4 items-start">
                  <div className="flex-shrink-0 w-12 h-12 rounded-lg bg-indigo-100 flex items-center justify-center">
                    <History className="w-6 h-6 text-indigo-600" />
                  </div>
                  <div>
                    <h4 className="text-lg font-semibold text-gray-900">{t('promptmaster.version.title')}</h4>
                    <p className="text-gray-600">{t('promptmaster.version.description')}</p>
                  </div>
                </div>
                <div className="flex gap-4 items-start">
                  <div className="flex-shrink-0 w-12 h-12 rounded-lg bg-indigo-100 flex items-center justify-center">
                    <RefreshCw className="w-6 h-6 text-indigo-600" />
                  </div>
                  <div>
                    <h4 className="text-lg font-semibold text-gray-900">{t('promptmaster.testing.title')}</h4>
                    <p className="text-gray-600">{t('promptmaster.testing.description')}</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="grid md:grid-cols-2 lg:grid-cols-4 gap-8 mb-16">
          {categories.map((category, index) => (
            <div 
              key={index}
              className="p-6 rounded-xl border border-gray-100 hover:shadow-lg transition group"
            >
              <div className={`w-12 h-12 rounded-lg ${category.color} flex items-center justify-center mb-4`}>
                <category.icon className="w-6 h-6" />
              </div>
              <h3 className="text-xl font-semibold text-gray-900 mb-2">
                {t(category.nameKey)}
              </h3>
              <p className="text-gray-600 mb-4">
                {category.prompts} {t('category.prompts')}
              </p>
              <div className="flex items-center text-indigo-600 font-medium hover:text-indigo-700">
                {t('category.explore')} <Zap className="w-4 h-4 ml-2" />
              </div>
            </div>
          ))}
        </div>

        <div className="bg-gradient-to-r from-indigo-600 to-purple-600 rounded-2xl p-8 lg:p-12 text-white">
          <div className="grid md:grid-cols-2 gap-8 items-center">
            <div>
              <h3 className="text-3xl font-bold mb-4">{t('cta.title')}</h3>
              <p className="text-indigo-100 mb-6">{t('cta.subtitle')}</p>
              <button className="bg-white text-indigo-600 px-8 py-3 rounded-lg font-semibold hover:bg-indigo-50 transition">
                {t('cta.button')}
              </button>
            </div>
            <div className="grid grid-cols-2 gap-4 text-center">
              <div className="bg-white/10 rounded-lg p-4 backdrop-blur-sm">
                <div className="text-3xl font-bold mb-1">1M+</div>
                <div className="text-indigo-100">{t('cta.stats.prompts')}</div>
              </div>
              <div className="bg-white/10 rounded-lg p-4 backdrop-blur-sm">
                <div className="text-3xl font-bold mb-1">98%</div>
                <div className="text-indigo-100">{t('cta.stats.success')}</div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}