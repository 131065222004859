import React from 'react';
import { useLanguage } from '../context/LanguageContext';
import { Brain, Mail, Phone, MapPin } from 'lucide-react';

export default function Footer() {
  const { t } = useLanguage();
  const currentYear = new Date().getFullYear();

  return (
    <footer className="bg-gray-900 text-gray-300">
      <div className="container mx-auto px-4 py-12">
        <div className="grid md:grid-cols-4 gap-8 mb-8">
          {/* Company Info */}
          <div>
            <div className="flex items-center gap-2 mb-4">
              <Brain className="h-8 w-8 text-indigo-400" />
              <span className="text-xl font-bold text-white">AImagination</span>
            </div>
            <p className="text-sm text-gray-400 mb-4">
              {t('hero.subtitle')}
            </p>
          </div>

          {/* Quick Links */}
          <div>
            <h3 className="text-white font-semibold mb-4">{t('nav.features')}</h3>
            <ul className="space-y-2">
              <li>
                <a href="#features" className="text-gray-400 hover:text-indigo-400 text-sm">
                  {t('features.analysis.title')}
                </a>
              </li>
              <li>
                <a href="#audit" className="text-gray-400 hover:text-indigo-400 text-sm">
                  {t('audit.title')}
                </a>
              </li>
              <li>
                <a href="#prompts" className="text-gray-400 hover:text-indigo-400 text-sm">
                  {t('promptmaster.title')}
                </a>
              </li>
              <li>
                <a href="#pricing" className="text-gray-400 hover:text-indigo-400 text-sm">
                  {t('pricing.title')}
                </a>
              </li>
            </ul>
          </div>

          {/* Legal */}
          <div>
            <h3 className="text-white font-semibold mb-4">{t('legal.title')}</h3>
            <ul className="space-y-2">
              <li>
                <a href="#privacy" className="text-gray-400 hover:text-indigo-400 text-sm">
                  {t('legal.privacy')}
                </a>
              </li>
              <li>
                <a href="#terms" className="text-gray-400 hover:text-indigo-400 text-sm">
                  {t('legal.terms')}
                </a>
              </li>
            </ul>
          </div>

          {/* Contact */}
          <div>
            <h3 className="text-white font-semibold mb-4">{t('legal.company')}</h3>
            <address className="not-italic">
              <div className="flex items-start gap-2 text-sm text-gray-400 mb-2">
                <MapPin className="w-5 h-5 flex-shrink-0 text-indigo-400" />
                <div>
                  <p>{t('legal.address')}</p>
                  <p>{t('legal.city')}</p>
                  <p>{t('legal.country')}</p>
                </div>
              </div>
              <p className="flex items-center gap-2 text-sm text-gray-400 mb-2">
                <Phone className="w-5 h-5 text-indigo-400" />
                <span>+32 477 94 28 65</span>
              </p>
              <p className="flex items-center gap-2 text-sm text-gray-400">
                <Mail className="w-5 h-5 text-indigo-400" />
                <span>divers@distr-action.com</span>
              </p>
            </address>
          </div>
        </div>

        {/* Bottom Bar */}
        <div className="border-t border-gray-800 pt-8">
          <div className="flex flex-col md:flex-row justify-between items-center gap-4">
            <div className="text-sm text-gray-400">
              © {currentYear} {t('legal.company')} - {t('legal.rights')}
            </div>
            <div className="text-sm text-gray-400">
              {t('legal.vat')}
            </div>
          </div>
        </div>
      </div>
    </footer>
  );
}