import React from 'react';
import { 
  ArrowLeft,
  Pencil,
  Type,
  Languages,
  Target,
  ArrowRight,
  Zap,
  FileText
} from 'lucide-react';
import { useNavigate } from 'react-router-dom';

export default function ContentWriting() {
  const navigate = useNavigate();

  const features = [
    {
      icon: Type,
      title: "Rédaction Automatisée",
      description: "Générez du contenu de qualité rapidement"
    },
    {
      icon: Languages,
      title: "Multi-langues",
      description: "Support de plus de 20 langues"
    },
    {
      icon: Target,
      title: "Contenu Ciblé",
      description: "Adapté à votre audience"
    },
    {
      icon: FileText,
      title: "Formats Variés",
      description: "Articles, emails, réseaux sociaux..."
    }
  ];

  const examples = [
    {
      type: "Article Blog",
      title: "10 Tendances IA pour 2024",
      preview: "L'intelligence artificielle continue de transformer...",
      stats: {
        words: "1,200",
        time: "2 min"
      }
    },
    {
      type: "Email Marketing",
      title: "Lancement Nouveau Produit",
      preview: "Découvrez notre dernière innovation...",
      stats: {
        words: "300",
        time: "30 sec"
      }
    },
    {
      type: "Post LinkedIn",
      title: "Étude de Cas Client",
      preview: "Comment nous avons aidé une entreprise à...",
      stats: {
        words: "200",
        time: "20 sec"
      }
    }
  ];

  return (
    <section className="py-20 bg-gradient-to-b from-gray-50 to-white">
      <div className="container mx-auto px-4">
        <button 
          onClick={() => navigate('/')}
          className="mb-8 flex items-center gap-2 text-indigo-600 hover:text-indigo-700 transition-colors"
        >
          <ArrowLeft className="w-5 h-5" />
          Retour à l'accueil
        </button>

        <div className="max-w-3xl mx-auto text-center mb-16">
          <span className="text-indigo-600 font-semibold text-sm uppercase tracking-wide">
            Rédaction IA
          </span>
          <h1 className="text-4xl font-bold text-gray-900 mt-2 mb-4">
            Création de Contenu Intelligente
          </h1>
          <p className="text-xl text-gray-600">
            Générez du contenu engageant en quelques clics
          </p>
        </div>

        {/* Fonctionnalités */}
        <div className="grid md:grid-cols-2 lg:grid-cols-4 gap-8 mb-16">
          {features.map((feature, index) => (
            <div key={index} className="bg-white rounded-xl shadow-lg p-6">
              <div className="w-12 h-12 bg-indigo-100 rounded-lg flex items-center justify-center mb-4">
                <feature.icon className="w-6 h-6 text-indigo-600" />
              </div>
              <h3 className="text-lg font-semibold text-gray-900 mb-2">
                {feature.title}
              </h3>
              <p className="text-gray-600">
                {feature.description}
              </p>
            </div>
          ))}
        </div>

        {/* Exemples */}
        <div className="grid md:grid-cols-3 gap-8 mb-16">
          {examples.map((example, index) => (
            <div key={index} className="bg-white rounded-xl shadow-lg p-6">
              <div className="flex items-center gap-2 text-sm text-indigo-600 font-medium mb-3">
                <Pencil className="w-4 h-4" />
                {example.type}
              </div>
              <h3 className="text-lg font-semibold text-gray-900 mb-2">
                {example.title}
              </h3>
              <p className="text-gray-600 mb-4">
                {example.preview}
              </p>
              <div className="flex items-center justify-between text-sm text-gray-500">
                <span>{example.stats.words} mots</span>
                <span>{example.stats.time} génération</span>
              </div>
            </div>
          ))}
        </div>

        {/* CTA */}
        <div className="bg-indigo-600 rounded-2xl p-8 lg:p-12 text-white">
          <div className="grid md:grid-cols-2 gap-8 items-center">
            <div>
              <h3 className="text-3xl font-bold mb-4">
                Créez du contenu maintenant
              </h3>
              <p className="text-indigo-100 mb-6">
                Essayez notre outil de rédaction IA
              </p>
              <button className="bg-white text-indigo-600 px-8 py-3 rounded-lg font-semibold hover:bg-indigo-50 transition flex items-center gap-2">
                Commencer
                <ArrowRight className="w-5 h-5" />
              </button>
            </div>
            <div className="grid grid-cols-2 gap-4 text-center">
              <div className="bg-white/10 rounded-lg p-4 backdrop-blur-sm">
                <Zap className="w-8 h-8 text-white mx-auto mb-2" />
                <div className="text-3xl font-bold mb-1">2M+</div>
                <div className="text-indigo-100">Articles Générés</div>
              </div>
              <div className="bg-white/10 rounded-lg p-4 backdrop-blur-sm">
                <FileText className="w-8 h-8 text-white mx-auto mb-2" />
                <div className="text-3xl font-bold mb-1">20+</div>
                <div className="text-indigo-100">Langues</div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}