import React from 'react';
import { Brain, BarChart3, Lock } from 'lucide-react';
import { useLanguage } from '../context/LanguageContext';
import FreeTrialForm from './FreeTrialForm';
import RequestDemo from './RequestDemo';

export default function Hero() {
  const { t } = useLanguage();

  return (
    <div className="relative overflow-hidden bg-gradient-to-b from-indigo-50 to-white pt-20 lg:pt-32 pb-12 lg:pb-20">
      <div className="container mx-auto px-4 sm:px-6 lg:px-8">
        <div className="grid lg:grid-cols-2 gap-8 lg:gap-12 items-center">
          <div className="text-center lg:text-left">
            <h1 className="text-3xl sm:text-4xl lg:text-5xl font-bold text-gray-900 leading-tight mb-4 lg:mb-6">
              {t('section.aiAnalysis')}
            </h1>
            <p className="text-lg sm:text-xl text-gray-600 mb-8">
              {t('features.analysis.desc')}
            </p>
            <div className="flex flex-col sm:flex-row gap-4 justify-center lg:justify-start">
              <FreeTrialForm />
              <RequestDemo />
            </div>
            
            <div className="mt-8 lg:mt-12 grid grid-cols-1 sm:grid-cols-3 gap-4 sm:gap-8">
              <div className="flex items-center justify-center lg:justify-start gap-2">
                <BarChart3 className="text-indigo-600 w-5 h-5" />
                <span className="text-sm text-gray-600">{t('stats.accuracy')} 99.9%</span>
              </div>
              <div className="flex items-center justify-center lg:justify-start gap-2">
                <Lock className="text-indigo-600 w-5 h-5" />
                <span className="text-sm text-gray-600">{t('stats.uptime')} 24/7</span>
              </div>
              <div className="flex items-center justify-center lg:justify-start gap-2">
                <Brain className="text-indigo-600 w-5 h-5" />
                <span className="text-sm text-gray-600">{t('stats.trained')} 5K+</span>
              </div>
            </div>
          </div>
          
          <div className="relative mt-8 lg:mt-0">
            <div className="absolute inset-0 bg-gradient-to-tr from-indigo-500/30 to-purple-500/30 rounded-3xl blur-3xl"></div>
            <img 
              src="https://images.unsplash.com/photo-1551434678-e076c223a692?ixlib=rb-4.0.3&auto=format&fit=crop&w=2070&q=80"
              alt="AI Business Navigator Dashboard"
              className="relative rounded-2xl shadow-2xl w-full"
            />
          </div>
        </div>
      </div>
    </div>
  );
}