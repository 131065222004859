import React, { useState } from 'react';
import { 
  Scan, 
  FileSearch, 
  PieChart, 
  Lightbulb,
  ArrowRight 
} from 'lucide-react';
import { useLanguage } from '../context/LanguageContext';
import AuditForm from './AuditForm';
import Button from './common/Button';

export default function AuditSection() {
  const { t } = useLanguage();
  const [showForm, setShowForm] = useState<boolean>(false);

  const auditSteps = [
    {
      id: 1,
      icon: Scan,
      titleKey: 'audit.steps.analysis.title',
      descriptionKey: 'audit.steps.analysis.desc'
    },
    {
      id: 2,
      icon: FileSearch,
      titleKey: 'audit.steps.diagnostic.title',
      descriptionKey: 'audit.steps.diagnostic.desc'
    },
    {
      id: 3,
      icon: PieChart,
      titleKey: 'audit.steps.impact.title',
      descriptionKey: 'audit.steps.impact.desc'
    },
    {
      id: 4,
      icon: Lightbulb,
      titleKey: 'audit.steps.plan.title',
      descriptionKey: 'audit.steps.plan.desc'
    }
  ];

  const stats = [
    {
      value: '93%',
      label: t('audit.stats.implementation')
    },
    {
      value: '45%',
      label: t('audit.stats.roi')
    },
    {
      value: '2-4x',
      label: t('audit.stats.acceleration')
    }
  ];

  return (
    <section id="audit" className="py-20 bg-gradient-to-b from-white to-indigo-50">
      <div className="container mx-auto px-6">
        <div className="max-w-3xl mx-auto text-center mb-16">
          <h2 className="text-4xl font-bold text-gray-900 mb-4">
            {t('audit.title')}
          </h2>
          <p className="text-xl text-gray-600">
            {t('audit.subtitle')}
          </p>
        </div>

        {showForm ? (
          <AuditForm />
        ) : (
          <>
            <div className="grid lg:grid-cols-2 gap-12">
              <div className="space-y-8">
                {auditSteps.map((step) => (
                  <div 
                    key={step.id}
                    className="flex gap-4 p-6 rounded-xl transition-all duration-300 hover:bg-white/50"
                  >
                    <div className="w-12 h-12 rounded-full bg-indigo-100 text-indigo-600 flex items-center justify-center">
                      <step.icon className="w-6 h-6" />
                    </div>
                    <div>
                      <h3 className="text-xl font-semibold text-gray-900 mb-2">
                        {t(step.titleKey)}
                      </h3>
                      <p className="text-gray-600">
                        {t(step.descriptionKey)}
                      </p>
                    </div>
                  </div>
                ))}
              </div>

              <div className="bg-white rounded-2xl shadow-xl p-8">
                <div className="grid grid-cols-3 gap-8">
                  {stats.map((stat, index) => (
                    <div key={index} className="text-center">
                      <div className="text-3xl font-bold text-indigo-600 mb-2">
                        {stat.value}
                      </div>
                      <p className="text-sm text-gray-600">
                        {stat.label}
                      </p>
                    </div>
                  ))}
                </div>

                <div className="mt-8 text-center">
                  <Button
                    onClick={() => setShowForm(true)}
                    variant="primary"
                    size="lg"
                    className="w-full"
                  >
                    {t('button.startAudit')}
                    <ArrowRight className="w-5 h-5" />
                  </Button>
                </div>
              </div>
            </div>

            <div className="mt-12 text-center">
              <Button
                onClick={() => setShowForm(true)}
                variant="outline"
                size="lg"
                className="mx-auto"
              >
                {t('button.requestDemo')}
              </Button>
            </div>
          </>
        )}
      </div>
    </section>
  );
}