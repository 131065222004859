import React from 'react';
import { Check, Zap, Shield, Brain, Star } from 'lucide-react';
import { useLanguage } from '../context/LanguageContext';

export default function Pricing() {
  const { t } = useLanguage();

  const plans = [
    {
      name: t('pricing.starter.name'),
      description: t('pricing.starter.description'),
      price: '49',
      icon: Brain,
      features: [
        t('pricing.starter.features.f1'),
        t('pricing.starter.features.f2'),
        t('pricing.starter.features.f3'),
        t('pricing.starter.features.f4')
      ],
      color: 'bg-gray-50 hover:bg-gray-100',
      buttonClass: 'bg-gray-900 hover:bg-gray-800'
    },
    {
      name: t('pricing.pro.name'),
      description: t('pricing.pro.description'),
      price: '99',
      icon: Zap,
      features: [
        t('pricing.pro.features.f1'),
        t('pricing.pro.features.f2'),
        t('pricing.pro.features.f3'),
        t('pricing.pro.features.f4'),
        t('pricing.pro.features.f5'),
        t('pricing.pro.features.f6')
      ],
      popular: true,
      color: 'bg-indigo-50 hover:bg-indigo-100',
      buttonClass: 'bg-indigo-600 hover:bg-indigo-700'
    },
    {
      name: t('pricing.enterprise.name'),
      description: t('pricing.enterprise.description'),
      price: '299',
      icon: Shield,
      features: [
        t('pricing.enterprise.features.f1'),
        t('pricing.enterprise.features.f2'),
        t('pricing.enterprise.features.f3'),
        t('pricing.enterprise.features.f4'),
        t('pricing.enterprise.features.f5'),
        t('pricing.enterprise.features.f6'),
        t('pricing.enterprise.features.f7')
      ],
      color: 'bg-purple-50 hover:bg-purple-100',
      buttonClass: 'bg-purple-600 hover:bg-purple-700'
    }
  ];

  return (
    <section id="pricing" className="py-12 sm:py-16 lg:py-20 bg-white">
      <div className="container mx-auto px-4 sm:px-6 lg:px-8">
        <div className="text-center mb-12 lg:mb-16">
          <h2 className="text-3xl sm:text-4xl font-bold text-gray-900 mb-4">
            {t('pricing.title')}
          </h2>
          <p className="text-lg sm:text-xl text-gray-600 max-w-2xl mx-auto">
            {t('pricing.subtitle')}
          </p>
        </div>

        <div className="grid md:grid-cols-2 lg:grid-cols-3 gap-8 max-w-7xl mx-auto">
          {plans.map((plan, index) => (
            <div
              key={index}
              className={`rounded-2xl p-6 sm:p-8 transition-all duration-300 transform hover:scale-105 ${plan.color} relative`}
            >
              {plan.popular && (
                <div className="absolute -top-4 left-1/2 transform -translate-x-1/2">
                  <div className="bg-gradient-to-r from-indigo-500 to-purple-500 text-white px-4 py-1 rounded-full text-sm font-medium flex items-center gap-1">
                    <Star className="w-4 h-4" />
                    {t('pricing.popular')}
                  </div>
                </div>
              )}
              
              <div className={`w-12 h-12 rounded-lg bg-white flex items-center justify-center mb-4`}>
                <plan.icon className={`w-6 h-6 ${plan.popular ? 'text-indigo-600' : 'text-gray-600'}`} />
              </div>

              <h3 className="text-xl sm:text-2xl font-bold text-gray-900 mb-2">{plan.name}</h3>
              <p className="text-gray-600 mb-6 text-sm sm:text-base">{plan.description}</p>

              <div className="mb-6">
                <span className="text-3xl sm:text-4xl font-bold text-gray-900">${plan.price}</span>
                <span className="text-gray-600">{t('pricing.monthly')}</span>
              </div>

              <ul className="space-y-4 mb-8">
                {plan.features.map((feature, idx) => (
                  <li key={idx} className="flex items-start gap-3">
                    <Check className={`w-5 h-5 mt-0.5 ${plan.popular ? 'text-indigo-600' : 'text-gray-600'}`} />
                    <span className="text-gray-600 text-sm sm:text-base">{feature}</span>
                  </li>
                ))}
              </ul>

              <button
                className={`w-full py-3 px-6 rounded-lg text-white font-semibold transition-colors ${plan.buttonClass}`}
              >
                {plan.name === t('pricing.enterprise.name') 
                  ? t('pricing.contactUs') 
                  : t('pricing.startTrial')}
              </button>
            </div>
          ))}
        </div>
      </div>
    </section>
  );
}