import React, { memo } from 'react';
import { Brain, BarChart3, Users, Pencil, MessageSquare, LineChart, BookOpen, Award } from 'lucide-react';
import { useLanguage } from '../context/LanguageContext';
import { useNavigate } from 'react-router-dom';

interface FeatureCardProps {
  icon: React.ElementType;
  titleKey: string;
  descriptionKey: string;
  onClick?: () => void;
}

const features = [
  {
    icon: Brain,
    titleKey: 'features.analysis.title',
    descriptionKey: 'features.analysis.desc',
    path: '/analyse-ia'
  },
  {
    icon: BarChart3,
    titleKey: 'features.recommendations.title',
    descriptionKey: 'features.recommendations.desc',
    path: '/recommandations'
  },
  {
    icon: LineChart,
    titleKey: 'features.dashboard.title',
    descriptionKey: 'features.dashboard.desc',
    path: '/dashboard'
  },
  {
    icon: BookOpen,
    titleKey: 'features.library.title',
    descriptionKey: 'features.library.desc',
    path: '/prompt-library'
  },
  {
    icon: Users,
    titleKey: 'features.collaboration.title',
    descriptionKey: 'features.collaboration.desc',
    path: '/collaboration'
  },
  {
    icon: Award,
    titleKey: 'features.training.title',
    descriptionKey: 'features.training.desc',
    path: '/training'
  },
  {
    icon: Pencil,
    titleKey: 'features.writing.title',
    descriptionKey: 'features.writing.desc',
    path: '/content-writing'
  },
  {
    icon: MessageSquare,
    titleKey: 'features.support.title',
    descriptionKey: 'features.support.desc',
    path: '/support'
  }
];

const FeatureCard: React.FC<FeatureCardProps> = memo(({ icon: Icon, titleKey, descriptionKey, onClick }) => {
  const { t } = useLanguage();
  
  return (
    <div 
      className="p-6 rounded-xl border border-gray-100 hover:border-indigo-100 hover:shadow-lg transition group cursor-pointer"
      onClick={onClick}
    >
      <Icon className="h-10 w-10 text-indigo-600 mb-4 group-hover:scale-110 transition" />
      <h3 className="text-lg sm:text-xl font-semibold text-gray-900 mb-2">
        {t(titleKey)}
      </h3>
      <p className="text-gray-600 text-sm sm:text-base">
        {t(descriptionKey)}
      </p>
    </div>
  );
});

FeatureCard.displayName = 'FeatureCard';

const Features: React.FC = () => {
  const { t } = useLanguage();
  const navigate = useNavigate();

  return (
    <section id="features" className="py-12 sm:py-16 lg:py-20 bg-white">
      <div className="container mx-auto px-4 sm:px-6 lg:px-8">
        <div className="text-center mb-12 lg:mb-16">
          <h2 className="text-3xl sm:text-4xl font-bold text-gray-900 mb-4">
            {t('features.analysis.title')}
          </h2>
          <p className="text-lg sm:text-xl text-gray-600 max-w-2xl mx-auto">
            {t('features.analysis.desc')}
          </p>
        </div>

        <div className="grid sm:grid-cols-2 lg:grid-cols-4 gap-6 lg:gap-8">
          {features.map((feature, index) => (
            <FeatureCard 
              key={index} 
              {...feature} 
              onClick={feature.path ? () => navigate(feature.path) : undefined}
            />
          ))}
        </div>
      </div>
    </section>
  );
};

export default memo(Features);