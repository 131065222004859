import React from 'react';
import { Routes, Route } from 'react-router-dom';
import Header from './components/Header';
import Hero from './components/Hero';
import Features from './components/Features';
import AIAnalysis from './components/AIAnalysis';
import SmartRecommendations from './components/SmartRecommendations';
import Dashboard from './components/Dashboard';
import PromptLibrary from './components/PromptLibrary';
import TeamCollaboration from './components/TeamCollaboration';
import CustomTraining from './components/CustomTraining';
import ContentWriting from './components/ContentWriting';
import Support from './components/Support';
import AuditSection from './components/AuditSection';
import PromptMaster from './components/PromptMaster';
import Pricing from './components/Pricing';
import Footer from './components/Footer';

function App() {
  return (
    <div className="min-h-screen bg-white">
      <Header />
      <Routes>
        <Route path="/" element={
          <main>
            <Hero />
            <Features />
            <AuditSection />
            <PromptMaster />
            <Pricing />
          </main>
        } />
        <Route path="/analyse-ia" element={
          <main className="pt-16">
            <AIAnalysis />
          </main>
        } />
        <Route path="/recommandations" element={
          <main className="pt-16">
            <SmartRecommendations />
          </main>
        } />
        <Route path="/dashboard" element={
          <main className="pt-16">
            <Dashboard />
          </main>
        } />
        <Route path="/prompt-library" element={
          <main className="pt-16">
            <PromptLibrary />
          </main>
        } />
        <Route path="/collaboration" element={
          <main className="pt-16">
            <TeamCollaboration />
          </main>
        } />
        <Route path="/training" element={
          <main className="pt-16">
            <CustomTraining />
          </main>
        } />
        <Route path="/content-writing" element={
          <main className="pt-16">
            <ContentWriting />
          </main>
        } />
        <Route path="/support" element={
          <main className="pt-16">
            <Support />
          </main>
        } />
      </Routes>
      <Footer />
    </div>
  );
}

export default App;