import React from 'react';
import { 
  ArrowLeft,
  GraduationCap,
  Target,
  Layers,
  LineChart,
  ArrowRight,
  Zap,
  Users
} from 'lucide-react';
import { useNavigate } from 'react-router-dom';

export default function CustomTraining() {
  const navigate = useNavigate();

  const features = [
    {
      icon: Target,
      title: "Formation Personnalisée",
      description: "Programmes adaptés à vos besoins spécifiques"
    },
    {
      icon: Layers,
      title: "Modules Progressifs",
      description: "Apprentissage par niveaux de compétence"
    },
    {
      icon: LineChart,
      title: "Suivi des Progrès",
      description: "Analyses détaillées des performances"
    },
    {
      icon: GraduationCap,
      title: "Certification",
      description: "Validez vos compétences acquises"
    }
  ];

  const modules = [
    {
      title: "Fondamentaux de l'IA",
      duration: "2 semaines",
      level: "Débutant",
      topics: ["Introduction à l'IA", "Concepts clés", "Applications pratiques"]
    },
    {
      title: "Prompts Avancés",
      duration: "3 semaines",
      level: "Intermédiaire",
      topics: ["Techniques d'optimisation", "Cas d'usage", "Best practices"]
    },
    {
      title: "Spécialisation Métier",
      duration: "4 semaines",
      level: "Avancé",
      topics: ["Solutions sectorielles", "Intégration", "Projets réels"]
    }
  ];

  return (
    <section className="py-20 bg-gradient-to-b from-gray-50 to-white">
      <div className="container mx-auto px-4">
        <button 
          onClick={() => navigate('/')}
          className="mb-8 flex items-center gap-2 text-indigo-600 hover:text-indigo-700 transition-colors"
        >
          <ArrowLeft className="w-5 h-5" />
          Retour à l'accueil
        </button>

        <div className="max-w-3xl mx-auto text-center mb-16">
          <span className="text-indigo-600 font-semibold text-sm uppercase tracking-wide">
            Formation Personnalisée
          </span>
          <h1 className="text-4xl font-bold text-gray-900 mt-2 mb-4">
            Formation IA Sur Mesure
          </h1>
          <p className="text-xl text-gray-600">
            Développez les compétences IA de votre équipe
          </p>
        </div>

        {/* Fonctionnalités */}
        <div className="grid md:grid-cols-2 lg:grid-cols-4 gap-8 mb-16">
          {features.map((feature, index) => (
            <div key={index} className="bg-white rounded-xl shadow-lg p-6">
              <div className="w-12 h-12 bg-indigo-100 rounded-lg flex items-center justify-center mb-4">
                <feature.icon className="w-6 h-6 text-indigo-600" />
              </div>
              <h3 className="text-lg font-semibold text-gray-900 mb-2">
                {feature.title}
              </h3>
              <p className="text-gray-600">
                {feature.description}
              </p>
            </div>
          ))}
        </div>

        {/* Modules */}
        <div className="grid md:grid-cols-3 gap-8 mb-16">
          {modules.map((module, index) => (
            <div key={index} className="bg-white rounded-xl shadow-lg p-6">
              <div className="flex items-center gap-2 text-sm text-indigo-600 font-medium mb-3">
                <GraduationCap className="w-4 h-4" />
                {module.level}
              </div>
              <h3 className="text-lg font-semibold text-gray-900 mb-2">
                {module.title}
              </h3>
              <p className="text-gray-600 mb-4">
                Durée: {module.duration}
              </p>
              <ul className="space-y-2">
                {module.topics.map((topic, idx) => (
                  <li key={idx} className="text-gray-600 text-sm">
                    • {topic}
                  </li>
                ))}
              </ul>
            </div>
          ))}
        </div>

        {/* CTA */}
        <div className="bg-indigo-600 rounded-2xl p-8 lg:p-12 text-white">
          <div className="grid md:grid-cols-2 gap-8 items-center">
            <div>
              <h3 className="text-3xl font-bold mb-4">
                Formez votre équipe
              </h3>
              <p className="text-indigo-100 mb-6">
                Commencez votre parcours de formation personnalisé
              </p>
              <button className="bg-white text-indigo-600 px-8 py-3 rounded-lg font-semibold hover:bg-indigo-50 transition flex items-center gap-2">
                Démarrer
                <ArrowRight className="w-5 h-5" />
              </button>
            </div>
            <div className="grid grid-cols-2 gap-4 text-center">
              <div className="bg-white/10 rounded-lg p-4 backdrop-blur-sm">
                <Zap className="w-8 h-8 text-white mx-auto mb-2" />
                <div className="text-3xl font-bold mb-1">95%</div>
                <div className="text-indigo-100">Satisfaction</div>
              </div>
              <div className="bg-white/10 rounded-lg p-4 backdrop-blur-sm">
                <Users className="w-8 h-8 text-white mx-auto mb-2" />
                <div className="text-3xl font-bold mb-1">5k+</div>
                <div className="text-indigo-100">Formés</div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}