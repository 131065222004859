import React from 'react';
import { 
  ArrowLeft,
  BookOpen,
  Search,
  Tag,
  Star,
  Copy,
  ArrowRight,
  Zap,
  Users
} from 'lucide-react';
import { useNavigate } from 'react-router-dom';

export default function PromptLibrary() {
  const navigate = useNavigate();

  const categories = [
    {
      name: "Marketing",
      count: 245,
      color: "bg-blue-100 text-blue-600"
    },
    {
      name: "Service Client",
      count: 189,
      color: "bg-green-100 text-green-600"
    },
    {
      name: "Ventes",
      count: 167,
      color: "bg-purple-100 text-purple-600"
    },
    {
      name: "RH",
      count: 134,
      color: "bg-orange-100 text-orange-600"
    }
  ];

  const popularPrompts = [
    {
      title: "Analyse de Sentiment Client",
      description: "Analysez les retours clients pour identifier les tendances",
      category: "Service Client",
      rating: 4.9,
      uses: "12.5k"
    },
    {
      title: "Génération de Contenu Marketing",
      description: "Créez du contenu engageant pour vos campagnes",
      category: "Marketing",
      rating: 4.8,
      uses: "15.2k"
    },
    {
      title: "Optimisation des Emails",
      description: "Améliorez vos taux d'ouverture et de conversion",
      category: "Ventes",
      rating: 4.7,
      uses: "9.8k"
    }
  ];

  return (
    <section className="py-20 bg-gradient-to-b from-gray-50 to-white">
      <div className="container mx-auto px-4">
        <button 
          onClick={() => navigate('/')}
          className="mb-8 flex items-center gap-2 text-indigo-600 hover:text-indigo-700 transition-colors"
        >
          <ArrowLeft className="w-5 h-5" />
          Retour à l'accueil
        </button>

        <div className="max-w-3xl mx-auto text-center mb-16">
          <span className="text-indigo-600 font-semibold text-sm uppercase tracking-wide">
            Bibliothèque de Prompts
          </span>
          <h1 className="text-4xl font-bold text-gray-900 mt-2 mb-4">
            Explorez Notre Collection de Prompts
          </h1>
          <p className="text-xl text-gray-600">
            Des milliers de prompts optimisés pour votre entreprise
          </p>
        </div>

        {/* Barre de recherche */}
        <div className="max-w-2xl mx-auto mb-16">
          <div className="relative">
            <Search className="absolute left-4 top-1/2 -translate-y-1/2 text-gray-400 w-5 h-5" />
            <input
              type="text"
              placeholder="Rechercher un prompt..."
              className="w-full pl-12 pr-4 py-3 rounded-lg border border-gray-200 focus:border-indigo-500 focus:ring-2 focus:ring-indigo-200"
            />
          </div>
        </div>

        {/* Catégories */}
        <div className="grid sm:grid-cols-2 lg:grid-cols-4 gap-6 mb-16">
          {categories.map((category, index) => (
            <div 
              key={index}
              className="bg-white rounded-xl shadow-lg p-6 hover:shadow-xl transition cursor-pointer"
            >
              <div className={`w-12 h-12 ${category.color} rounded-lg flex items-center justify-center mb-4`}>
                <Tag className="w-6 h-6" />
              </div>
              <h3 className="text-lg font-semibold text-gray-900 mb-2">
                {category.name}
              </h3>
              <p className="text-gray-600">
                {category.count} prompts
              </p>
            </div>
          ))}
        </div>

        {/* Prompts Populaires */}
        <div className="mb-16">
          <h2 className="text-2xl font-bold text-gray-900 mb-8">
            Prompts Populaires
          </h2>
          <div className="grid md:grid-cols-2 lg:grid-cols-3 gap-8">
            {popularPrompts.map((prompt, index) => (
              <div key={index} className="bg-white rounded-xl shadow-lg p-6">
                <div className="flex items-center gap-2 text-sm text-indigo-600 font-medium mb-3">
                  <BookOpen className="w-4 h-4" />
                  {prompt.category}
                </div>
                <h3 className="text-lg font-semibold text-gray-900 mb-2">
                  {prompt.title}
                </h3>
                <p className="text-gray-600 mb-4">
                  {prompt.description}
                </p>
                <div className="flex items-center justify-between">
                  <div className="flex items-center gap-2">
                    <Star className="w-5 h-5 text-yellow-400 fill-current" />
                    <span className="font-medium">{prompt.rating}</span>
                  </div>
                  <div className="flex items-center gap-2">
                    <Copy className="w-5 h-5 text-gray-400" />
                    <span className="text-gray-600">{prompt.uses}</span>
                  </div>
                </div>
              </div>
            ))}
          </div>
        </div>

        {/* CTA */}
        <div className="bg-indigo-600 rounded-2xl p-8 lg:p-12 text-white">
          <div className="grid md:grid-cols-2 gap-8 items-center">
            <div>
              <h3 className="text-3xl font-bold mb-4">
                Créez vos propres prompts
              </h3>
              <p className="text-indigo-100 mb-6">
                Personnalisez et optimisez vos prompts pour votre entreprise
              </p>
              <button className="bg-white text-indigo-600 px-8 py-3 rounded-lg font-semibold hover:bg-indigo-50 transition flex items-center gap-2">
                Commencer
                <ArrowRight className="w-5 h-5" />
              </button>
            </div>
            <div className="grid grid-cols-2 gap-4 text-center">
              <div className="bg-white/10 rounded-lg p-4 backdrop-blur-sm">
                <Zap className="w-8 h-8 text-white mx-auto mb-2" />
                <div className="text-3xl font-bold mb-1">1M+</div>
                <div className="text-indigo-100">Prompts Créés</div>
              </div>
              <div className="bg-white/10 rounded-lg p-4 backdrop-blur-sm">
                <Users className="w-8 h-8 text-white mx-auto mb-2" />
                <div className="text-3xl font-bold mb-1">50k+</div>
                <div className="text-indigo-100">Utilisateurs</div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}