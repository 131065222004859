import React from 'react';
import { 
  ArrowLeft,
  Users,
  Share2,
  MessageSquare,
  Lock,
  ArrowRight,
  Zap,
  CheckCircle
} from 'lucide-react';
import { useNavigate } from 'react-router-dom';
import { useLanguage } from '../context/LanguageContext';

export default function TeamCollaboration() {
  const navigate = useNavigate();
  const { t } = useLanguage();

  return (
    <section className="py-20 bg-gradient-to-b from-gray-50 to-white">
      <div className="container mx-auto px-4">
        <button 
          onClick={() => navigate('/')}
          className="mb-8 flex items-center gap-2 text-indigo-600 hover:text-indigo-700 transition-colors"
        >
          <ArrowLeft className="w-5 h-5" />
          {t('button.backHome')}
        </button>

        {/* ... autre contenu ... */}
        
        {/* CTA */}
        <div className="bg-indigo-600 rounded-2xl p-8 lg:p-12 text-white">
          <div className="grid md:grid-cols-2 gap-8 items-center">
            <div>
              <h3 className="text-3xl font-bold mb-4">
                {t('collaboration.cta.title')}
              </h3>
              <p className="text-indigo-100 mb-6">
                {t('collaboration.cta.subtitle')}
              </p>
              <button className="bg-white text-indigo-600 px-8 py-3 rounded-lg font-semibold hover:bg-indigo-50 transition flex items-center gap-2">
                {t('button.startFreeTrial')}
                <ArrowRight className="w-5 h-5" />
              </button>
            </div>
            {/* ... stats ... */}
          </div>
        </div>
      </div>
    </section>
  );
}