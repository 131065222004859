import React, { useState } from 'react';
import { useLanguage } from '../context/LanguageContext';
import { X, ArrowRight, Mail } from 'lucide-react';
import Button from './common/Button';

export default function FreeTrialForm() {
  const { t } = useLanguage();
  const [isOpen, setIsOpen] = useState(false);
  const [isSubmitted, setIsSubmitted] = useState(false);
  const [email, setEmail] = useState('');

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    setIsSubmitted(true);
  };

  return (
    <>
      <Button
        onClick={() => setIsOpen(true)}
        variant="primary"
        className="flex items-center gap-2"
      >
        {t('button.startFreeTrial')}
      </Button>

      {isOpen && (
        <div className="fixed inset-0 bg-black/50 flex items-center justify-center p-4 z-50">
          <div className="bg-white rounded-2xl w-full max-w-md relative">
            <button
              onClick={() => setIsOpen(false)}
              className="absolute right-4 top-4 text-gray-400 hover:text-gray-600"
            >
              <X className="w-6 h-6" />
            </button>

            <div className="p-8">
              {!isSubmitted ? (
                <>
                  <h2 className="text-2xl font-bold text-gray-900 mb-2">
                    {t('trial.title')}
                  </h2>
                  <p className="text-gray-600 mb-6">
                    {t('trial.subtitle')}
                  </p>

                  <div className="bg-indigo-50 rounded-lg p-4 mb-6">
                    <h3 className="font-semibold text-indigo-900 mb-2">{t('trial.includes')}</h3>
                    <ul className="space-y-2">
                      <li className="flex items-center text-indigo-700">
                        <span className="w-2 h-2 bg-indigo-500 rounded-full mr-2"></span>
                        {t('trial.feature1')}
                      </li>
                      <li className="flex items-center text-indigo-700">
                        <span className="w-2 h-2 bg-indigo-500 rounded-full mr-2"></span>
                        {t('trial.feature2')}
                      </li>
                      <li className="flex items-center text-indigo-700">
                        <span className="w-2 h-2 bg-indigo-500 rounded-full mr-2"></span>
                        {t('trial.feature3')}
                      </li>
                    </ul>
                  </div>

                  <form onSubmit={handleSubmit} className="space-y-4">
                    <div>
                      <label className="block text-sm font-medium text-gray-700 mb-1">
                        {t('trial.form.email')}
                      </label>
                      <div className="relative">
                        <Mail className="absolute left-3 top-1/2 -translate-y-1/2 text-gray-400 w-5 h-5" />
                        <input
                          type="email"
                          required
                          value={email}
                          onChange={(e) => setEmail(e.target.value)}
                          className="w-full pl-10 pr-4 py-2 border border-gray-300 rounded-lg focus:ring-2 focus:ring-indigo-500 focus:border-indigo-500"
                          placeholder={t('trial.form.emailPlaceholder')}
                        />
                      </div>
                    </div>

                    <button
                      type="submit"
                      className="w-full bg-indigo-600 text-white py-3 rounded-lg hover:bg-indigo-700 transition-colors duration-300 flex items-center justify-center gap-2"
                    >
                      {t('trial.form.submit')}
                      <ArrowRight className="w-4 h-4" />
                    </button>

                    <p className="text-xs text-gray-500 text-center">
                      {t('trial.form.terms')}
                    </p>
                  </form>
                </>
              ) : (
                <div className="text-center py-8">
                  <div className="w-16 h-16 bg-green-100 rounded-full flex items-center justify-center mx-auto mb-4">
                    <Mail className="w-8 h-8 text-green-500" />
                  </div>
                  <h3 className="text-2xl font-bold text-gray-900 mb-2">
                    {t('trial.success.title')}
                  </h3>
                  <p className="text-gray-600">
                    {t('trial.success.message')}
                  </p>
                  <button
                    onClick={() => setIsOpen(false)}
                    className="mt-6 px-6 py-2 bg-indigo-600 text-white rounded-lg hover:bg-indigo-700 transition-colors duration-300"
                  >
                    {t('trial.success.close')}
                  </button>
                </div>
              )}
            </div>
          </div>
        </div>
      )}
    </>
  );
}