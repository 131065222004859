const translations = {
  en: {
    // Navigation
    'nav.solutions': 'Solutions',
    'nav.tools': 'Tools',
    'nav.services': 'Services',
    'nav.pricing': 'Pricing',
    'nav.getStarted': 'Get Started',
    'nav.analysis': 'AI Analysis',
    'nav.recommendations': 'Smart Recommendations',
    'nav.dashboard': 'Dashboard',
    'nav.prompts': 'Prompt Library',
    'nav.content': 'Content Writing',
    'nav.training': 'Training',
    'nav.collaboration': 'Collaboration',
    'nav.support': 'Support',

    // Sections
    'section.aiAnalysis': 'Powerful AI Analysis for Your Business',
    'section.recommendations': 'Smart AI-Powered Recommendations',

    // Features
    'features.analysis.title': 'Powerful AI Data Analysis',
    'features.analysis.desc': 'Transform your data into actionable insights with our advanced AI analysis tools',
    'features.recommendations.title': 'Smart AI-Powered Recommendations',
    'features.recommendations.desc': 'Get personalized recommendations to optimize your business processes',
    'features.dashboard.title': 'Interactive Dashboard',
    'features.dashboard.desc': 'Monitor your performance in real-time with our intuitive dashboard',
    'features.library.title': 'Extensive Prompt Library',
    'features.library.desc': 'Access our curated collection of optimized prompts for various use cases',

    // Buttons
    'button.getStarted': 'Get Started',
    'button.startFreeTrial': 'Start Free Trial',
    'button.startNow': 'Start Now',
    'button.learnMore': 'Learn More',
    'button.backHome': 'Back to Home',
    'button.requestDemo': 'Request Demo',
    'button.startAnalysis': 'Start Analysis',
    'button.startAudit': 'Start Free Audit',

    // Demo Request
    'demo.request': 'Request Demo',
    'demo.title': 'Schedule a Demo',
    'demo.subtitle': 'See our AI solutions in action',
    'demo.form.name': 'Full Name',
    'demo.form.namePlaceholder': 'Enter your full name',
    'demo.form.company': 'Company',
    'demo.form.companyPlaceholder': 'Your company name',
    'demo.form.email': 'Business Email',
    'demo.form.emailPlaceholder': 'you@company.com',
    'demo.form.phone': 'Phone',
    'demo.form.phonePlaceholder': 'Your phone number',
    'demo.form.companySize': 'Company Size',
    'demo.form.companySizePlaceholder': 'Select company size',
    'demo.form.employees': 'employees',
    'demo.form.preferredDate': 'Preferred Demo Date',
    'demo.form.interests': 'Areas of Interest',
    'demo.form.submit': 'Schedule Demo',
    'demo.success.title': 'Demo Scheduled!',
    'demo.success.message': 'We\'ll contact you shortly to confirm your demo',
    'demo.success.close': 'Close',

    // Trial Modal
    'trial.title': 'Start Your Free Trial',
    'trial.subtitle': 'Experience the full power of our AI platform',
    'trial.includes': 'What\'s included:',
    'trial.feature1': '14 days full access',
    'trial.feature2': 'All premium features',
    'trial.feature3': 'Priority support',
    'trial.form.email': 'Business Email',
    'trial.form.emailPlaceholder': 'you@company.com',
    'trial.form.submit': 'Start Free Trial',
    'trial.form.terms': 'By starting a trial, you agree to our Terms of Service and Privacy Policy',
    'trial.success.title': 'Welcome aboard!',
    'trial.success.message': 'Check your email to activate your trial',
    'trial.success.close': 'Close',

    // Get Started Form
    'getStarted.title': 'Get Started with AImagination',
    'getStarted.subtitle': 'Discover how AI can transform your business',
    'getStarted.form.name': 'Your Name',
    'getStarted.form.namePlaceholder': 'Enter your full name',
    'getStarted.form.company': 'Company',
    'getStarted.form.companyPlaceholder': 'Your company name',
    'getStarted.form.email': 'Email',
    'getStarted.form.emailPlaceholder': 'Your business email',
    'getStarted.form.phone': 'Phone',
    'getStarted.form.phonePlaceholder': 'Your phone number',
    'getStarted.form.submit': 'Get Started',
    'getStarted.form.cancel': 'Cancel',
    'getStarted.success.title': 'Request Sent!',
    'getStarted.success.message': 'We\'ll be in touch shortly',
    'getStarted.success.close': 'Close'
  },
  fr: {
    // Navigation
    'nav.solutions': 'Solutions',
    'nav.tools': 'Outils',
    'nav.services': 'Services',
    'nav.pricing': 'Tarifs',
    'nav.getStarted': 'Commencer',
    'nav.analysis': 'Analyse IA',
    'nav.recommendations': 'Recommandations',
    'nav.dashboard': 'Tableau de bord',
    'nav.prompts': 'Bibliothèque de prompts',
    'nav.content': 'Rédaction',
    'nav.training': 'Formation',
    'nav.collaboration': 'Collaboration',
    'nav.support': 'Support',

    // Sections
    'section.aiAnalysis': 'Analyse puissante de vos données par l\'IA',
    'section.recommendations': 'Recommandations intelligentes alimentées par l\'IA',

    // Features
    'features.analysis.title': 'Analyse puissante de données par l\'IA',
    'features.analysis.desc': 'Transformez vos données en insights actionnables avec nos outils d\'analyse IA',
    'features.recommendations.title': 'Recommandations intelligentes par l\'IA',
    'features.recommendations.desc': 'Obtenez des recommandations personnalisées pour optimiser vos processus',
    'features.dashboard.title': 'Tableau de bord interactif',
    'features.dashboard.desc': 'Surveillez vos performances en temps réel avec notre tableau de bord intuitif',
    'features.library.title': 'Bibliothèque de prompts',
    'features.library.desc': 'Accédez à notre collection de prompts optimisés pour différents cas d\'usage',

    // Buttons
    'button.getStarted': 'Commencer',
    'button.startFreeTrial': 'Essai gratuit',
    'button.startNow': 'Commencer maintenant',
    'button.learnMore': 'En savoir plus',
    'button.backHome': 'Retour à l\'accueil',
    'button.requestDemo': 'Demander une démo',
    'button.startAnalysis': 'Démarrer l\'analyse',
    'button.startAudit': 'Audit gratuit',

    // Demo Request
    'demo.request': 'Demander une démo',
    'demo.title': 'Planifier une démo',
    'demo.subtitle': 'Découvrez nos solutions IA en action',
    'demo.form.name': 'Nom complet',
    'demo.form.namePlaceholder': 'Entrez votre nom complet',
    'demo.form.company': 'Entreprise',
    'demo.form.companyPlaceholder': 'Nom de votre entreprise',
    'demo.form.email': 'Email professionnel',
    'demo.form.emailPlaceholder': 'vous@entreprise.com',
    'demo.form.phone': 'Téléphone',
    'demo.form.phonePlaceholder': 'Votre numéro de téléphone',
    'demo.form.companySize': 'Taille de l\'entreprise',
    'demo.form.companySizePlaceholder': 'Sélectionnez la taille',
    'demo.form.employees': 'employés',
    'demo.form.preferredDate': 'Date de démo souhaitée',
    'demo.form.interests': 'Domaines d\'intérêt',
    'demo.form.submit': 'Planifier la démo',
    'demo.success.title': 'Démo planifiée !',
    'demo.success.message': 'Nous vous contacterons rapidement pour confirmer',
    'demo.success.close': 'Fermer',

    // Trial Modal
    'trial.title': 'Démarrez votre essai gratuit',
    'trial.subtitle': 'Découvrez toute la puissance de notre plateforme IA',
    'trial.includes': 'Ce qui est inclus :',
    'trial.feature1': '14 jours d\'accès complet',
    'trial.feature2': 'Toutes les fonctionnalités premium',
    'trial.feature3': 'Support prioritaire',
    'trial.form.email': 'Email professionnel',
    'trial.form.emailPlaceholder': 'vous@entreprise.com',
    'trial.form.submit': 'Démarrer l\'essai gratuit',
    'trial.form.terms': 'En démarrant l\'essai, vous acceptez nos Conditions d\'utilisation et notre Politique de confidentialité',
    'trial.success.title': 'Bienvenue !',
    'trial.success.message': 'Vérifiez votre email pour activer votre essai',
    'trial.success.close': 'Fermer',

    // Get Started Form
    'getStarted.title': 'Commencer avec AImagination',
    'getStarted.subtitle': 'Découvrez comment l\'IA peut transformer votre entreprise',
    'getStarted.form.name': 'Votre nom',
    'getStarted.form.namePlaceholder': 'Entrez votre nom complet',
    'getStarted.form.company': 'Entreprise',
    'getStarted.form.companyPlaceholder': 'Nom de votre entreprise',
    'getStarted.form.email': 'Email',
    'getStarted.form.emailPlaceholder': 'Votre email professionnel',
    'getStarted.form.phone': 'Téléphone',
    'getStarted.form.phonePlaceholder': 'Votre numéro de téléphone',
    'getStarted.form.submit': 'Commencer',
    'getStarted.form.cancel': 'Annuler',
    'getStarted.success.title': 'Demande envoyée !',
    'getStarted.success.message': 'Nous vous contacterons très prochainement',
    'getStarted.success.close': 'Fermer'
  }
};

export type TranslationKey = keyof typeof translations.en;
export default translations;